import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, NgZone, OnInit, ViewChild} from '@angular/core';
import { Location } from '@angular/common';
import { fadeInOut2, fadeInOut } from '../../../../../animations';
import {DefaultFormComponent, FormServiceInterface, ModalsServiceInterface, NavItem, UserServiceInterface, ValueAccessorBase} from '@hutsix/ngxh6';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-incident-form',
  templateUrl: './incident-form.component.html',
  styleUrls: ['./incident-form.component.scss'],
    animations: [fadeInOut, fadeInOut2],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IncidentFormComponent extends DefaultFormComponent {
    public sliding = false;
    public staffSearch: any = { searchTerm: '' };

    public navItems: NavItem[] = [
        { id: 'reasons', label: 'Why were we needed?', destroyOnHide: false },
        { id: 'actions', label: 'What did we do?', destroyOnHide: false },
        // {id: 'contributions', label: 'What contributed', destroyOnHide: false},
        { id: 'comments', label: 'Comments', destroyOnHide: false },
    ];

    @ViewChild(NgbCarousel) carousel: NgbCarousel;

    constructor(
        @Inject('FormService') public formService: FormServiceInterface,
        @Inject('UserService') public userService: UserServiceInterface,
        public location: Location,
        public cdRef: ChangeDetectorRef,

        public zone: NgZone,
        @Inject('ModalsService') public modals: ModalsServiceInterface,
    ) {
        super(formService, userService, location, cdRef);
    }

    public changeSlide(dir: 'next' | 'prev'): void {
        this.form
            .submit(false)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                res => {
                    if (dir === 'next') this.carousel.next();
                    if (dir === 'prev') this.carousel.prev();
                    this.cdRef.detectChanges();
                },
                err => {
                    this.cdRef.detectChanges();
                },
            );
    }

    public end(): void {
        this.submit();
    }

    communityChanged($e: any): void {
        this.staffSearch.searchTerm = $e.community?.string || '';
    }
}
