export const environment = {
    production: true,
    env: 'staging',
    apiUrl: 'https://api.staging.ramp.cdrc.app',
    whitelistedDomains: ['api.staging.ramp.cdrc.app'],
    blacklistedRoutes: [],
    // TODO: update it back to imagixDomain when imagix works properly
    imgixDomain: 'https://cdrc-app-assets.s3.ap-southeast-2.amazonaws.com',
    vapidPubKey: 'BK3Mdi-jR5tkM7fVVZaKfq3uMQMc0ZuTjOcneEbjJqSmZ50MfEo-KJIrMQwqsOMwCCzBmbg7swaZ-Q0c6AaeNDw',
};
