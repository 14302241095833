import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ValueAccessorBase} from "@hutsix/ngxh6";
import {NgControl} from "@angular/forms";

@Component({
  selector: 'app-sf-form-involved',
  templateUrl: './sf-form-involved.component.html',
  styleUrls: ['./sf-form-involved.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SfFormInvolvedComponent extends ValueAccessorBase implements OnChanges {
    public choices = [
        {choice: 'age0to12', label: '0 - 12'},
        {choice: 'age13to17', label: '13 - 17'},
        {choice: 'age18to25', label: '18 - 25'},
        {choice: 'age26to45', label: '26 - 45'},
        {choice: 'age46plus', label: '46+'},
    ];

    public inputValue: any = {};

    constructor(public cdRef: ChangeDetectorRef, public ngControl: NgControl) {
        super(cdRef, ngControl);
    }

    ngOnChanges(changes: SimpleChanges): void {
        // console.log(this.view.vars);

        super.ngOnChanges(changes);
    }

    minus(choice): void {
        if (this.inputValue[choice]) this.inputValue[choice]--;
        if (!this.inputValue[choice]) this.inputValue[choice] = null;
        this.onInputChange();
    }

    add(choice): void {
        if (!this.inputValue[choice]) this.inputValue[choice] = 0;
        this.inputValue[choice]++;
        this.inputValue = JSON.parse(JSON.stringify(this.inputValue));
        this.onInputChange();
    }

    writeValue(value: any): void {
        if (value === undefined || value === null) value = {};
        super.writeValue(value);
    }
}
