import {DefaultFilterComponent, DefaultFormComponent, FormOptions} from '@hutsix/ngxh6';
import {UserFormComponent} from '../modules/_shared/components/forms/custom-forms/user-form/user-form.component';
import forms_conf from '../_generated/ext_config/forms.json';
import {IncidentFormComponent} from "../modules/csp/components/forms/incident-form/incident-form.component";

export const custom_filters: { [key: string]: any } = {
  DefaultFilterComponent: DefaultFilterComponent,
};

export const custom_forms: { [key: string]: any } = {
  DefaultFormEmbedComponent: DefaultFormComponent,
  UserFormComponent: UserFormComponent,
  IncidentFormComponent: IncidentFormComponent
};

/** Override generated form values here */
const forms_conf_overrides: Override = {
  incident: {
    object_name: 'Incident',
    object_class: 'App\\Entity\\CSP\\Incident\\Incident',
    api_get: '/api/csp/incidents/form',
    allow_edits: true,
    allow_archive: true,
    offline_create: true,
    offline_edit: true,
    cache_strategy: 'refresh',
    offline_endpoint: '/api/v1/csp/incidents',
    success_route: 'csp/incidents',
    create_string: 'Log Incident',
    edit_string: 'Log Incident',
    edit_success_string: 'Incident Saved',
    create_success_string: 'Incident Saved',
    component: 'IncidentFormComponent',
  },
  action: {
    object_name: 'Action',
    object_class: 'App\\Entity\\CSP\\Action',
    api_get: '/api/csp/action/form',
    allow_edits: true,
    allow_archive: true,
    success_route: 'csp/config',
    modal_size: 'md',
    modal_display: 'vertical',
  },
  reason: {
    object_name: 'Incident Reason',
    object_class: 'App\\Entity\\CSP\\Reason',
    api_get: '/api/csp/reason/form',
    allow_edits: true,
    allow_archive: true,
    success_route: 'csp/config',
    modal_size: 'md',
    modal_display: 'vertical',
  },
  contribution: {
    object_name: 'Contributing Factor',
    object_class: 'App\\Entity\\CSP\\Contribution',
    api_get: '/api/csp/contribution/form',
    allow_edits: true,
    allow_archive: true,
    success_route: 'csp/config',
    modal_size: 'md',
  },
  service_type: {
    object_name: 'Service Type',
    object_class: 'App\\Entity\\CSP\\ServiceType',
    api_get: '/api/csp/service-type/form',
    allow_edits: true,
    allow_archive: true,
    success_route: 'csp/config',
    modal_size: 'md',
    modal_display: 'vertical',
  },
  staff: {
    object_name: 'Staff',
    object_class: 'App\\Entity\\CSP\\Staff',
    api_get: '/api/user/csp-staff/form',
    allow_edits: true,
    allow_archive: true,
    offline_create: true,
    offline_edit: true,
    cache_strategy: 'refresh',
    offline_endpoint: '/api/v1/csp/users',
    success_route: 'csp/staff',
    slug: 'csp_staff'
  },
  service_delivery: {
    object_name: 'Service Delivery',
    object_class: 'App\\Entity\\CSP\\Service\\ServiceDelivery',
    api_get: '/api/csp/service_delivery/form',
    allow_edits: true,
    allow_archive: true,
    offline_create: true,
    offline_edit: true,
    cache_strategy: 'refresh',
    offline_endpoint: '/api/v1/csp/service_deliveries',
    success_route: 'csp/service-deliveries',
    display: 'vertical',
  },
  scheduled_service: {
    object_name: 'Service Delivery',
    object_class: 'App\\Entity\\CSP\\Service\\ServiceDelivery',
    api_get: '/api/csp/scheduled_service/form',
    allow_edits: true,
    allow_archive: true,
    offline_create: true,
    offline_edit: true,
    cache_strategy: 'refresh',
    offline_endpoint: '/api/v1/csp/service_deliveries',
    success_route: 'csp/service-deliveries',
    display: 'vertical',
  },
};

/** Create custom forms or override generated forms here */
const forms = {
  'import-council': new FormOptions({
    api_get: '/api/import/council',
    cache_strategy: 'freshness',
    object_name: 'Import',
    create_string: 'Import Council Locations',
    submit_label: 'Import',
    allow_edits: false,
    create_success_string: 'Import succeeded!',
  }),
  'import-community': new FormOptions({
    api_get: '/api/import/community',
    cache_strategy: 'freshness',
    object_name: 'Import',
    create_string: 'Import Community Locations',
    submit_label: 'Import',
    allow_edits: false,
    create_success_string: 'Import succeeded!',
  }),
  'import-staff': new FormOptions({
    api_get: '/api/import/staff',
    cache_strategy: 'freshness',
    object_name: 'Import',
    create_string: 'Import Staff',
    submit_label: 'Import',
    allow_edits: false,
    create_success_string: 'Import succeeded!',
  }),
};

/**
 *
 * You shouldn't need to change anything below
 *
 */
export function getFormsConfig(): { [key: string]: FormOptions } {
  const f = JSON.parse(JSON.stringify(forms_conf));
  for (const [k, v] of Object.entries(forms_conf_overrides)) {
    if (f[k]) {
      f[k] = Object.assign(forms_conf[k], v);
    } else {
      console.warn(`Attempt to override generated form failed. Key '${k}' doesn't exist`);
    }
  }
  return {...f, ...forms};
}

export function getForm(slug: FormKeys): FormOptions {
  return getFormsConfig()[slug];
}

declare global {
  type FormKeys = keyof typeof forms | keyof typeof forms_conf;
}

type Override = { [Property in keyof Partial<typeof forms_conf>]: Partial<FormOptions> };
