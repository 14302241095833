import { Injectable } from '@angular/core';
import { FormOptions, FormService } from '@hutsix/ngxh6';

/**
 * Helper service for creating, routing to, or opening FormObjects.
 */
@Injectable()
export class CDRCFormService extends FormService {
    /**
     * Returns the generated route for the given form slug.
     */
    public getRoute(slug: FormKeys | FormOptions, editId?: string): string {
        const segments = ['ramp', 'csp', 'user-admin'];
        const segment = this.router.url.split('/')[1];
        const prepend = segments.includes(segment) ? segment + '/' : '';

        const f = this.forms[slug.toString()];
        let route = f.route ? f.route : prepend + 'form/' + slug;
        if (editId && this.forms[slug.toString()].allow_edits) {
            route = (f.edit_route || route) + '/' + editId;
        }
        return '/' + route;
    }
}
