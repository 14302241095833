import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {SfFormSelectExpandedComponent} from "@hutsix/ngxh6";
import {NgControl} from "@angular/forms";

@Component({
  selector: 'sf-form-select-imagegrid',
  templateUrl: './sf-form-select-imagegrid.component.html',
  styleUrls: ['./sf-form-select-imagegrid.component.scss']
})
export class SfFormSelectImagegridComponent extends SfFormSelectExpandedComponent implements OnInit {
    public objectKeys = Object.keys;

    constructor(public cdRef: ChangeDetectorRef, public ngControl: NgControl) {
        super(cdRef, ngControl);
    }

    ngOnInit(): void {
        super.ngOnInit();
    }
}
