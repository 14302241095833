import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnChanges, OnInit} from '@angular/core';
import {ModalsServiceInterface, SfFormCollectionComponent} from "@hutsix/ngxh6";
import {NgControl, Validator} from "@angular/forms";

@Component({
  selector: 'app-sf-form-action-entry',
  templateUrl: './sf-form-action-entry.component.html',
  styleUrls: ['./sf-form-action-entry.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SfFormActionEntryComponent extends SfFormCollectionComponent implements Validator, OnChanges, OnInit {
    @Input() involved?: any;// Involved_default;

    constructor(public cdRef: ChangeDetectorRef, public ngControl: NgControl, @Inject('ModalsService') public modalService: ModalsServiceInterface) {
        super(cdRef, ngControl, modalService);
    }

    addItem(data: any = {}): void {
        data.involved = JSON.parse(JSON.stringify(this.involved));
        super.addItem(data);
    }
}
