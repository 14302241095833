<nav class="navbar navbar-expand-lg fixed-top"
     [ngClass]="{
        'navbar-dark': !router.url.includes('/csp'),
        'navbar-csp': router.url.includes('/csp')
     }">
    <a
       class="navbar-brand d-flex align-items-center py-2 ms-2"
       routerLink="/">
        <span *ngIf="router.url.includes('/ramp')">RAMP App</span>
        <span *ngIf="router.url.includes('/csp')">CSP App</span>
        <span *ngIf="!router.url.includes('/csp') && !router.url.includes('/ramp')">CDRC</span>
    </a>


    <ng-container *ngIf="userService.watch | async as _user">
        <div class="ms-auto" ngbDropdown display="dynamic" placement="bottom-end" *ngIf="_user.loggedIn">
            <button class="btn profile-button" id="userDropdownMenu" ngbDropdownToggle (click)="update()" *ngIf="_user.loggedIn">
                <a class="notification" routerLink="user-admin/notifications" (click)="$event.stopPropagation()">
                    <ngxh6-notification-badge [outline]="true"></ngxh6-notification-badge>
                </a>
                <ngxh6-usericon [user]="_user.account.user" [showTooltip]="false" class="me-0"></ngxh6-usericon>
            </button>

            <div ngbDropdownMenu aria-labelledby="userDropdownMenu">
                <h6 class="dropdown-header">{{ _user.account.user.name }}</h6>

                <button class="dropdown-item" routerLink="user-admin"><i class="fa fa-fw fa-user me-2"></i> My Profile</button>

                <button class="dropdown-item" routerLink="user-admin/notifications">
                    <i class="fa fa-fw fa-exclamation-circle me-2"></i> Notifications
                    <ngxh6-notification-badge class="ms-2"></ngxh6-notification-badge>
                </button>

                <button class="dropdown-item" routerLink="user-admin/offline">
                    <i class="fa fa-fw fa-wifi me-2"></i> Manage Offline Data
                    <ng-container *ngIf="offlineQueue$ | async as _offline">
                        <span class="badge-pill badge bg-success" *ngIf="_offline.length">{{ _offline.length }}</span>
                    </ng-container>
                </button>

                <div class="dropdown-divider"></div>
                <button class="dropdown-item warning" (click)="clearCaches()"><i class="fa fa-fw fa-trash me-2"></i> Clear Data Cache</button>

                <button class="dropdown-item danger" (click)="userService.logOut()"><i class="fa fa-fw fa-sign-out me-2"></i> Logout</button>

                <div class="dropdown-divider"></div>
                <h6 class="dropdown-header">Theme</h6>
                <button class="dropdown-item theme-selector" *ngFor="let theme of themeService.themesKeys" (click)="themeService.setTheme(theme)">
                    <i
                        class="fa fa-fw me-2"
                        [class.fa-check-square-o]="theme === themeService.currentTheme"
                        [class.fa-square-o]="theme !== themeService.currentTheme"
                    ></i>
                    {{ themeService.themes[theme] }}
                </button>

                <ng-container *ngIf="userService.hasRole('ROLE_RAMP_ADMIN')">
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" routerLink="debug"> <i class="fa fa-fw fa-bug me-2"></i> Debug</a>
                </ng-container>

                <div class="dropdown-divider"></div>
                <h6 class="dropdown-header">App Version {{ version }}</h6>
                <h6 class="dropdown-header">Environment: {{ env }}</h6>
            </div>
        </div>
    </ng-container>
</nav>
