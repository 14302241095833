import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoginComponent } from './modules/_shared/routes/user/login/login.component';
import { RampAuthGuardService } from './modules/_shared/services/ramp-auth-guard.service';
import { ReloadPageComponent } from './modules/_shared/routes/reload-page/reload-page.component';
import { DashboardComponent } from './modules/_shared/routes/dashboard/dashboard.component';
import {AppRoutes, DebugModule, FormRouteComponent, NotAuthorizedComponent, PageNotFoundComponent} from '@hutsix/ngxh6';
import { CspAuthGuardService } from './modules/_shared/services/csp-auth-guard.service';
import {AuthGuardService} from "./modules/_shared/services/auth-guard/auth-guard.service";

const routes: AppRoutes = [
    { path: 'reload', component: ReloadPageComponent },
    { path: 'login', component: LoginComponent },
    { path: 'form/:slug', canActivate: [AuthGuardService], component: FormRouteComponent },
    { path: 'form/:slug/:id', canActivate: [AuthGuardService], component: FormRouteComponent },
    {
        path: '',
        canActivate: [AuthGuardService],
        component: DashboardComponent,
    },
    {
        path: 'ramp',
        canActivate: [RampAuthGuardService],
        data: { roles: ['ROLE_RAMP_USER'] },
        loadChildren: () => import('./modules/ramp/ramp.module').then(m => m.RampModule),
    },
    {
        path: 'csp',
        canActivate: [CspAuthGuardService],
        data: { roles: ['ROLE_CSP_USER'] },
        loadChildren: () => import('./modules/csp/csp.module').then(m => m.CspModule),
    },
    {
        path: 'user-admin',
        loadChildren: () => import('./modules/user-admin/user-admin.module').then(m => m.UserAdminModule),
    },
    {
        path: 'debug',
        canActivate: [RampAuthGuardService, CspAuthGuardService],
        data: { roles: ['ROLE_RAMP_ADMIN'] },
        loadChildren: () => DebugModule,
    },
    { path: 'unauthorised', canActivate: [RampAuthGuardService, CspAuthGuardService], component: NotAuthorizedComponent },
    { path: '**', canActivate: [RampAuthGuardService, CspAuthGuardService], component: PageNotFoundComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
