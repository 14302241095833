<div class="table-responsive table-flush">
    <table class="table table-hover table-sticky-actions" [class.dim]="!complete">
        <thead>
            <tr>
                <th orderby [key]="crud?.options?.queryKey" [field]="'createdBy.id'">Staff</th>
                <th orderby [key]="crud?.options?.queryKey" [field]="'created'">Age</th>
                <th orderby [key]="crud?.options?.queryKey" [field]="'location.id'" style="width: 100%; min-width: 350px">Report</th>
                <th orderby [key]="crud?.options?.queryKey" [field]="'location.stream'">Stream</th>
                <th orderby [key]="crud?.options?.queryKey" [field]="'actioned'">Actioned</th>
                <th orderby [key]="crud?.options?.queryKey" [field]="'department.name'">Department</th>

                <th class="text-end"></th>
            </tr>
        </thead>
        <tbody *ngIf="response; else loading">
            <ng-container *ngFor="let item of response.data; let i = index">
                <ngxh6-offline-indicator [model]="item"></ngxh6-offline-indicator>

                <tr>
                    <td class="align-middle text-nowrap"><ngxh6-usericon [user]="item.createdBy"></ngxh6-usericon></td>
                    <td class="align-middle text-nowrap">{{ item.created | str2Date | amTimeAgo: true }}</td>
                    <td class="align-middle">
                        <div class="d-flex flex-column position-relative">
                            <a [routerLink]="'/ramp/reports/' + item.id">{{ item.location.string }}</a>
                            <span class="d-block text-truncate small" style="min-width: 350px; max-width: 350px">{{ item.report }}</span>
                        </div>
                    </td>
                    <td class="align-middle text-nowrap">{{ item.location?.streamLabel }}</td>
                    <td class="align-middle text-center">
                        <span *ngIf="!item.actioned" class="badge bg-dark">No</span>
                        <span *ngIf="item.actioned" class="badge bg-success" [ngbTooltip]="item.jobStatusLabel">
                            Yes
                            <i *ngIf="item.jobStatus === '10-TODO'" class="ms-1 fa fa-clock-o"></i>
                            <i *ngIf="item.jobStatus === '20-ACTIVE'" class="ms-1 fa fa-spinner"></i>
                            <i *ngIf="item.jobStatus === '30-PENDING'" class="ms-1 fa fa-spinner"></i>
                            <i *ngIf="item.jobStatus === '40-COMPLETE'" class="ms-1 fa fa-check"></i>
                            <i *ngIf="item.jobStatus === '50-CANCELLED'" class="ms-1 fa fa-times-circle"></i>
                        </span>
                    </td>
                    <td class="align-middle text-nowrap">{{ item.department?.name }}</td>

                    <td class="align-middle text-end">
                        <a class="btn btn-gray" [routerLink]="'/ramp/reports/' + item.id">View</a>
                    </td>
                </tr>
            </ng-container>
        </tbody>

        <ng-template #loading><tbody table-loader [cols]="6" [actions]="true" [error]="crud.error"></tbody></ng-template>
    </table>
</div>
