<div class="table-responsive table-flush">
    <table class="table table-hover table-sticky-actions" [class.dim]="!crud.complete">
        <thead>
            <tr>
                <th orderby [key]="crud?.options?.queryKey" [field]="'jobCard.nbr'" style="width: 50px">#</th>
                <th orderby [key]="crud?.options?.queryKey" [field]="'complete'" style="width: 50px">Done</th>
                <th orderby [key]="crud?.options?.queryKey" [field]="'jobCard.location.id'" style="width: 100%; min-width: 350px">Task</th>
                <th orderby [key]="crud?.options?.queryKey" [field]="'jobCard.priority'">Priority</th>
                <th orderby [key]="crud?.options?.queryKey" [field]="'jobCard.status'">Status</th>
                <th orderby [key]="crud?.options?.queryKey" [field]="'jobCard.slaBreach'">SLA</th>

                <th class="text-end">Actions</th>
            </tr>
        </thead>
        <tbody *ngIf="response; else loading">
            <ng-container *ngFor="let item of response.data; let i = index">
                <ngxh6-offline-indicator [model]="item"></ngxh6-offline-indicator>
                <tr>
                    <td class="align-middle">{{ item.jobCard.nbr }}</td>
                    <td class="align-middle text-center">
                        <ng-container *ngIf="isCompleteOffline(item.id)">
                            <ngxh6-bool-yes-no *ngIf="!completeTasks.has(item.id)" [value]="item.complete"></ngxh6-bool-yes-no>
                            <div class="badge bg-success" *ngIf="completeTasks.has(item.id)">Done</div>
                        </ng-container>
                    </td>
                    <td class="align-middle small">
                        <a [routerLink]="'/ramp/jobs/' + item.jobCard.id" [queryParams]="{ task: item.id }">{{ item.jobCard.location.string }} </a><br />
                        <span>{{ item.taskDescription }}</span>
                    </td>
                    <td class="align-middle">{{ item.jobCard.priorityLabel }}</td>
                    <td class="align-middle"><app-job-status [job]="item.jobCard"></app-job-status></td>
                    <td class="align-middle text-nowrap"><app-job-sla [job]="item.jobCard"></app-job-sla></td>

                    <td class="align-middle text-end">
                        <a class="btn btn-gray" [routerLink]="'/ramp/jobs/' + item.jobCard.id" [queryParams]="{ task: item.id }">View</a>
                    </td>
                </tr>
            </ng-container>
        </tbody>

        <ng-template #loading><tbody table-loader [cols]="7" [actions]="true" [error]="crud.error"></tbody></ng-template>
    </table>
</div>
