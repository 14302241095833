<div class="app-wrapper top-bar">
    <main>
        <div class="container-fluid px-2 px-md-4 px-lg-5 py-4 py-xl-5">
            <div class="content-container py-5">
                <ul class="nav row">
                    <li class="col-12 d-flex justify-content-center mb-5">
                        <img src="assets/logo.svg" style="width: 300px" alt="logo">
                    </li>
                    <li class="nav-item col-12 col-md-6 pb-3 d-flex justify-content-center">
                        <a class="btn btn-primary btn-lg" routerLink="/ramp" routerLinkActive="active">
                            Launch RAMP App
                        </a>
                    </li>

                    <li class="nav-item col-12 col-md-6 pb-3 d-flex justify-content-center">
                        <a class="btn btn-primary btn-lg" routerLink="/csp" routerLinkActive="active">
                            Launch CSP App
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </main>
</div>
