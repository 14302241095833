import { Inject, Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserServiceInterface } from '@hutsix/ngxh6';

@Injectable({
    providedIn: 'root',
})
export class RampAuthGuardService implements CanActivate {
    constructor(private router: Router, @Inject('UserService') public user: UserServiceInterface) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        // do some checks here
        return this.user.watch.pipe(
            map(user => {
                if (!user || !user.loggedIn) {
                    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
                    return false;
                }

                if (route.data?.roles?.length && !this.user.isGranted(route.data.roles)) {
                    this.router.navigate(['/unauthorised'], { queryParams: { route: state.url } });
                    return false;
                }

                let isRampUser = false;
                // Allow access if user roles includes 'RAMP'
                if (user && user.account.user.roles) {
                    isRampUser = user.account.user.roles.some((role: string) => role.includes('RAMP'));
                }

                console.log('isRampUser: ', isRampUser);

                return isRampUser;
            }),
        );
    }
}
