export const role_hierarchy: { [key: string]: string | string[] } = {



  // changes here need to be reflected in backend: security.yaml
  ROLE_ADMIN: ['ROLE_RAMP_ADMIN', 'ROLE_CSP_ADMIN'],
  // RAMP Roles
  ROLE_RAMP_ADMIN: 'ROLE_RAMP_APP_MANAGER',
  ROLE_RAMP_APP_MANAGER: 'ROLE_RAMP_MANAGER',
  ROLE_RAMP_MANAGER: ['ROLE_RAMP_HMO', 'ROLE_RAMP_REPORTER'],
  ROLE_RAMP_HMO: 'ROLE_RAMP_CAN_VIEW_JOBS',
  ROLE_RAMP_REPORTER: 'ROLE_RAMP_CAN_REPORT',
  ROLE_RAMP_RESTRICTED_REPORTER: 'ROLE_RAMP_CAN_REPORT',

  ROLE_RAMP_CAN_REPORT: 'ROLE_RAMP_USER',
  ROLE_RAMP_CAN_VIEW_JOBS: 'ROLE_RAMP_USER',

  // CSP Roles
  ROLE_CSP_ADMIN: 'ROLE_CSP_APP_MANAGER',

  ROLE_CSP_APP_MANAGER: 'ROLE_CSP_MANAGER',
  ROLE_CSP_MANAGER: ['ROLE_CSP_REGIONAL_MANAGER', 'ROLE_CSP_PROGRAM_MANAGER'],

  ROLE_CSP_REGIONAL_MANAGER: ['ROLE_CSP_SENIOR_COORDINATOR', 'ROLE_CSP_COORDINATOR'],
  ROLE_CSP_PROGRAM_MANAGER: ['ROLE_CSP_SENIOR_COORDINATOR', 'ROLE_CSP_COORDINATOR'],

  ROLE_CSP_SENIOR_COORDINATOR: ['ROLE_CSP_TEAM_LEADER', 'ROLE_CSP_FUTURE_LEADER'],
  ROLE_CSP_COORDINATOR: ['ROLE_CSP_TEAM_LEADER', 'ROLE_CSP_FUTURE_LEADER'],

  ROLE_CSP_TEAM_LEADER: 'ROLE_CSP_USER',
  ROLE_CSP_FUTURE_LEADER: 'ROLE_CSP_USER',

  ROLE_CSP_COMMUNITY_SAFETY_PATROL: 'ROLE_CSP_USER'
};
